import React from 'react'

function Footer() {
  return (
    <footer>
      <p>© 2023 CARA All rights reserved</p>
      <p>By <a href='https://www.linkedin.com/fatogoma'>Fatogoma</a></p>
    </footer>
  )
}

export default Footer